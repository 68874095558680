import React from "react";

import { Link } from 'gatsby';

import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import simpleFormStyle from "assets/jss/material-kit-react/views/landingPageSections/simpleFormStyle.jsx";

class BlogSubscribeSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem >
            <h2 className={classes.title}>Stay up to date!</h2>
            <h4 className={classes.description}>
              Subscribe to the blog so you can receive information about updates, news, changes, and more!
            </h4>
            <form>
              <GridContainer >
                <GridItem className={classes.form}>
                  <CustomInput
                    labelText="Your Name"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem className={classes.form}>
                  <CustomInput
                    labelText="Your Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                
                <Link className={classes.link} to ='/thanksForSubscription'>
                    Submit
                </Link>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(simpleFormStyle)(BlogSubscribeSection);